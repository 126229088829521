import { useState } from "react"
import { Form, Input } from "formik-antd"
import { validatePhone } from "../../hooks/requests"

const PhoneInput = ({
  name,
  hasFeedback,
  label,
  showValidateSuccess,
  required,
  tooltip,
  autoTooltip,
  placeholder,
  formik,
  disabled,
  errorMessage,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const tooltipText =
    "Please input Apt, Suite, etc. before entering the unit number"

  const handleFocus = () => {
    setIsTooltipVisible(true)
  }

  const handleBlur = () => {
    setIsTooltipVisible(false)
  }

  const validatePhoneInput = async (phone) => {
    if (!phone) return undefined

    try {
      const [url, options] = validatePhone(phone)
      const response = await fetch(url, options)

      if (response.ok) return undefined

      const data = await response.json()
      return data.number?.[0] || "Invalid phone number"
    } catch (err) {
      return err.message
    }
  }

  const formatPhoneNumber = (value) => {
    const digits = value.replace(/[^\d]/g, "")
    if (digits.length < 4) return digits
    if (digits.length < 7) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(
      6,
      10
    )}`
  }

  return (
    <Form.Item
      name={name}
      hasFeedback={hasFeedback}
      label={label}
      showValidateSuccess={showValidateSuccess}
      required={required}
      validate={validatePhoneInput}
      tooltip={{
        title: tooltip || tooltipText,
        visible: autoTooltip ? isTooltipVisible : undefined,
      }}
    >
      <Input
        name={name}
        size="large"
        inputMode="numeric"
        placeholder={placeholder}
        autoComplete="tel"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={(e) => {
          const formattedValue = formatPhoneNumber(e.target.value)
          formik.setFieldValue(name, formattedValue)
        }}
        disabled={disabled}
      />
    </Form.Item>
  )
}

export default PhoneInput
